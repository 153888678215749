import React from 'react'
import './Intro.css'
import logoImage from '../assets/images/logo.png';
import fl1Image from '../assets/images/fl-1.png';
import fl2Image from '../assets/images/fl-2.png';
import fl3Image from '../assets/images/fl-3.png';
import { useNavigate } from 'react-router';
import instaImage from '../assets/images/insta.png';

const Intro = () => {
    // 53.3 centimeter (cm) = 2014.488189 pixel (X)
    // Result: 95.1 centimeter (cm) = 3594.3307087 pixel (X)
    const navigate = useNavigate();

    return (
        <div className='body-intro'>
            <div className='body-intro-container margin-auto'>
                <div className='d-flex mt-80'>
                    <div className='logo ml-30'>
                        <img
                            src={logoImage}
                            alt="Logout"
                            className='img-intro-logo'
                        /></div>
                    <div><h1 className='color-white fs-60'>گُلت</h1>
                        <span className='color-white fs-35'>اپلیکیشن نگهداری گل و گیاه آپارتمانی</span>
                    </div>

                </div>

                <h2 className='justify-content-center fs-60 color-white mt-80'>مسابقه اطلاعات گل و گیاه</h2>

                <div className='justify-content-center mt-50'>
                    <img
                        src={fl1Image}
                        alt="Logout"
                        className='ml-30 img-intro'
                    />
                    <img
                        src={fl2Image}
                        alt="Logout"
                        className='ml-30 img-intro'
                    />

                    <img
                        src={fl3Image}
                        alt="Logout "
                        className='img-intro'
                    />
                </div>


                <div className='button-white fw-700 fs-60 mt-130' onClick={ () => navigate('/questions')}>
                    شروع بازی
                </div>


                <div className='justify-content-center flex-direction-column align-items-center mt-80'>
                    <h2 className='fs-35 color-white'>56 امتیاز بگیر، یک گل برنده شو</h2>
                    <h2 className='fs-35 color-white'>67 امتیاز بگیر تو قرعه کشی شرکت کن</h2>
                    <h2 className='fs-35 color-white'>اگه اپلیکیشن رو نصب کنی 10 امتیاز میگیری و شانست تو قرعه کشی بیشتر میشه</h2>
                </div>


                <div className='justify-content-center flex-direction-column align-items-center mt-80'>
                    <h2 className='fs-35 color-white'>توسعه توسط تیم برنامه نویسی جرگه</h2>
                </div>


          <div className='d-flex fs-35 fw-700 mt-80 justify-content-center color-white fs-35 align-items-end'>golet.app<img src={instaImage} alt="Logout" className='img-intro-insta'/></div>

            </div>
        </div>
    )
}

export default Intro
import React, { useEffect, useState } from 'react';
import './Ranking.css';
import gold from '../assets/images/gold-medal.png';
import close from '../assets/images/close-b.png';
import axios from 'axios';

const Ranking = () => {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [pointInstall, setPointInstall] = useState('');
    const [earnPoint, setEarnPoint] = useState();


    const maskMobile = (mobile) => {
        if (!mobile) return '';
        return mobile.replace(/(\d{5})\d{3}(\d{2})/, '$1***$2');
    };

    console.log(phoneNumber);
    console.log(pointInstall);
    console.log(earnPoint);



    useEffect(() => {
        const fetchUsers = async () => {
            
            try {
                const response = await axios.get('https://golmirzaei.ir/game/api/game/get-top-users-list');
                setUsers(response.data.list);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
    
        fetchUsers();
    
        const interval = setInterval(() => {
            fetchUsers();
        }, 150000);
    
        return () => clearInterval(interval);
    }, []);
    

    const handleSubmit = async () => {
        setLoading(true);
        setMessage('');
        try {
            const response = await axios.post('https://app.golet.app/golet/api/v1/website/log/check-number-has-photo', {
                phone_number: phoneNumber,
            });

            const { userRegister, photoTaken, photoCount } = response.data;

            await setEarnPoint(photoCount * 2)

            if (userRegister && photoTaken) {
                setPointInstall("15")
                const totalPoints = parseInt("15") + (photoCount * 2);

                setMessage(`شما در اپلیکیشن عضو شدید و با اپلیکیشن عکس گرفته‌اید، امتیاز کامل این بخش را دریافت می‌کنید. مجموع امتیاز شما: ${totalPoints}`);

            } else if (userRegister && !photoTaken) {
                setMessage('امتیاز شما ثبت شد، برای کسب امتیاز بیشتر، با اپلیکیشن از گیاهان عکس بگیرید');
                setPointInstall("10")
            } else {
                setMessage('شماره شما ثبت نشد. لطفاً دوباره تلاش کنید.');
                setPointInstall("")
            }
        } catch (error) {
            console.error('Error submitting phone number:', error);
            setMessage(error.response.data.error);
            setPointInstall("")
        } finally {
            setLoading(false);
        }
    };



    const handleSubmitAppScore = async () => {
        setLoading(true);
        try {
            const response = await axios.post('https://golmirzaei.ir/game/api/game/add-point', {
                mobile: phoneNumber,
                register_point: pointInstall,
                earn_point: earnPoint,

            });

            console.log(response.data);
            setShowModal(false); setPhoneNumber(''); setPointInstall(""); setEarnPoint(""); setMessage("")

            alert(response.data.message);
        } catch (error) {
            console.error('Error submitting phone number:', error);
            setMessage(error.response.data.error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='body-ranking'>
            <main>
                <div id="header">
                    <div>
                        <h1>اپلیکیشن گُلت</h1>
                        <div className='fs-32 text-center'>golet.app</div>
                    </div>
                    <button className="share" onClick={() => setShowModal(true)}>
                        <i className="ph ph-share-network pointer">افزودن امتیاز</i>
                    </button>
                </div>

                <h2 className='title-rank mb-16'>نفرات برتر شرکت کرده در مسابقه گُلت</h2>
                <div id="leaderboard">
                    <div className="ribbon"></div>
                    <table>
                        {users.map((user, index) => (
                            <tr key={index}>
                                <td className="number">{index + 1}</td>
                                <td className="name">{user.name || maskMobile(user.mobile)}</td>
                                <td className="points">
                                    {user.total_point}
                                    {index === 0 && <img className="gold-medal" src={gold} alt="gold medal" />}
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </main>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='d-flex justify-content-space-between mt-16' style={{ padding: '0 25px' }}>
                            <h2>افزودن شماره</h2>
                            <div className='pointer fs-32' onClick={() => { setShowModal(false); setPhoneNumber(''); setPointInstall(""); setEarnPoint(""); setMessage("") }}>
                                <img src={close} alt="close medal" />
                            </div>
                        </div>
                        <input
                            type="text"
                            className='input-rank'
                            placeholder="شماره موبایل"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <div className='mt-16'>
                            <button className='btn-send' onClick={handleSubmit} disabled={loading}>
                                {loading ? 'در حال ارسال ...' : 'ارسال'}
                            </button>
                        </div>

                        {message && <div className='mt-25 res-msg'>
                            {message && <p>{message}</p>}
                        </div>}


                        {parseInt(pointInstall) > 1 && (
                            <button className='btn-send bg-dark mt-16' onClick={handleSubmitAppScore} disabled={loading}>
                                {loading ? 'در حال ارسال ...' : 'ثبت نهایی'}
                            </button>
                        )}


                    </div>
                </div>
            )}
        </div>
    );
};

export default Ranking;
